import React from 'react';
import styled from 'styled-components';

import Section from './Section';
import Carousel from './Carousel';

import { colors } from '../shared/styles';

const CustomerSection = styled(Section)`
  background-color : ${colors.yellow};
  padding-top : 2rem;
  padding-bottom : 5rem;

  @media screen and (max-width: 425px) {
    margin-left : -4rem;
    margin-right : -4rem;
  }
`;


const CustomersTitle = styled.h2`
  font-family : TellMeMore;
  font-size : 4.5rem;
  text-transform : uppercase;
  letter-spacing : 1rem;
  text-align : center;
  margin-bottom : 5rem;
`;

const CustomerTile = styled.div.attrs(({src}) => ({
  style : {
    backgroundImage : `url('${src}')`
  }
}))`
  width : 12rem;
  height : 12rem;
  background-repeat : no-repeat;
  background-size : contain;
  background-position : center center;
`;





const Customers = props =>
  <CustomerSection>
    <CustomersTitle>Ils nous font confiance</CustomersTitle>
    <Carousel>
      <CustomerTile src={require('../images/clients/salomon.png')} />
      <CustomerTile src={require('../images/clients/henner.png')} />
      <CustomerTile src={require('../images/clients/botanic.png')} />
      <CustomerTile src={require('../images/clients/mustella.png')} />
      <CustomerTile src={require('../images/clients/sncf.png')} />
      <CustomerTile src={require('../images/clients/nouveau-monde.png')} />
      <CustomerTile src={require('../images/clients/nocibe.png')} />
      <CustomerTile src={require('../images/clients/hilti.png')} />
      <CustomerTile src={require('../images/clients/colorado.png')} />
      <CustomerTile src={require('../images/clients/init.png')} />
      <CustomerTile src={require('../images/clients/expanscience.png')} />
    </Carousel>
  </CustomerSection>;

export default Customers;
