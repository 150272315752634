import React, {useState} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display : flex;
  flex-direction : row;
  height : 15rem;
`;

const ArrowContainer = styled.div`
  width : 9rem;
  height : 100%;
`;

const Arrow = styled.div`
  width : 5rem;
  height : 12rem;
  background-repeat : no-repeat;
  background-position : center center;
  background-size : 4rem;
  margin : 0 2rem;
`;

const LeftArrow = styled(Arrow)`
  background-image : url('${require('../images/left.png')}');

  &.position0 {
    display : none;
  }
`;

const RightArrow = styled(Arrow)`
  background-image : url('${require('../images/right.png')}');

  @media screen and (max-width: 800px) {
    &.position${({size}) => size-4} {
      display : none;
    }
  }

  @media screen and (min-width: 801px) {
    &.position${({size}) => size-5} {
      display : none;
    }
  }
`;

const SlidesContainer = styled.div`
  flex : 1;
  flex-direction : row;
  margin : 0rem 5rem;
  overflow : hidden;
`;

const Slider = styled.div.attrs(({position, size}) => ({
  style : {
    transform : `translateX(${-position*100/size}%)`,
  }
}))`
  transition : transform 0.5s ease;

  @media screen and (max-width: 425px) {
    width : ${({size}) => 50*size}%;
  }

  @media screen and (min-width : 426px) and (max-width: 800px) {
    width : ${({size}) => 25*size}%;
  }

  @media screen and (min-width: 801px) {
    width : ${({size}) => 20*size}%;
  }
`;

const Slide = styled.div.attrs(({size}) => ({
  style : {
    width : `${100/size}%`
  }
}))`
  display : inline-flex;
  height : 15rem;
  flex-direction : row;
  justify-content : center;
`;

const Carousel = ({children}) => {
  const [position, setPosition] = useState(0);
  const size = React.Children.count(children);

  const previous = () => position && setPosition(position - 1);
  const next = () => {
    if (position < size - 1) { setPosition(position + 1); }
  }

  return (
    <Container>
      <ArrowContainer>
        <LeftArrow onClick={previous} className={`position${position}`}/>
      </ArrowContainer>
      <SlidesContainer>
        <Slider position={position} size={size}>
          {
            React.Children.map(children, child => (
              <Slide size={size}>
                {React.cloneElement(child)}
              </Slide>
            ))
          }
        </Slider>
      </SlidesContainer>
      <ArrowContainer>
        <RightArrow onClick={next} className={`position${position}`} size={size}/>
      </ArrowContainer>
    </Container>
  );
}

export default Carousel;
